/* Chrome, Safari 対応 */
.box::-webkit-scrollbar {
  display: none;
}

html, body ,#root {
  height: 100vh;
  height: 100dvh;
  overflow-y: hidden;
}

p {
  font-family: "japan_l";
}

h2 {
  font-family: "japan_l";
  color: #707070;
}

@font-face {
  font-family: "thin";
  src: url(./font/NotoSansJP-Thin.otf);
}

.japanese_thin {
  font-family: thin;
}

@font-face {
  font-family: "japan_l";
  src: url(./font/Koruri-Light.ttf);
}

.japanese_L {
  font-family: japan_l;
}

@font-face {
  font-family: "japan_b";
  src: url(./font/Koruri-Bold.ttf);
}

@font-face {
  font-family: "japan_r";
  src: url(./font/Koruri-Regular.ttf);
}

.japanese_B {
  font-family: japan_b;
}

.japanese_R {
  font-family: japan_r;
}

.defaultButton {
  background-color: "#E6E6E6";
  box-shadow: "none";
}

.plessButton {
  background-color: "#65FFA062";
  box-shadow: "inset 0px 3px 6px #00000029";
}

.center {
  text-align: center;
}

.themeFontColor {
  color: #006c9b;
}

.themeBackgroundColor {
  background-color: #006c9b;
}

div {
  font-family: japan_b;
  color: #111111;
}

.defaultButton {
  background-color: #006c9b 0% 0% no-repeat padding-box;
  width: 23vw;
  height: 10vw;
  box-shadow: inset 5px 5px 5px #00000029;
  color: #ffffff;
  border-radius: 17px;
  border: none;
}

.selectedButton {
  background: #848484 0% 0% no-repeat padding-box;
  color: #ffffff;
  border-radius: 17px;
  border: none;
}

.over {
  color: #006c9b;
  text-decoration: underline;
}

.over:hover {
  color: #e76d27;
}
